<template>
  <div>
    <v-row>
      <v-col md="6" sm="12" class="d-flex flex-column">
        <v-card class="flex" outlined tile>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="headline">
                {{ $t("card.detail-info") }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      v-if="singleRevision.memo.tipe === 'xnusantara'"
                      href="javascript:void(0)"
                      class="label theme-bg8 text-white f-12"
                      >{{ $t("badge.xnusantara-letter") }}</a
                    >
                  </template>
                  <span>{{ $t("tooltip.xnusantara-letter") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      v-if="singleRevision.memo.tipe === 'bom'"
                      href="javascript:void(0)"
                      class="label theme-bg4 text-white f-12"
                      >{{ $t("badge.directors-memo") }}</a
                    >
                  </template>
                  <span>{{ $t("tooltip.directors-memo") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      v-if="singleRevision.memo.tipe === 'subholding'"
                      href="javascript:void(0)"
                      class="label theme-bg7 text-white f-12"
                      >{{ $t("badge.subholding-memo") }}</a
                    >
                  </template>
                  <span>{{ $t("tooltip.subholding-memo") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      v-if="singleRevision.memo.tipe === 'masuk'"
                      href="javascript:void(0)"
                      class="label theme-bg6 text-white f-12"
                      >{{ $t("badge.incoming-letter") }}</a
                    >
                  </template>
                  <span>{{ $t("tooltip.incoming-letter") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      v-if="singleRevision.memo.tipe === 'eksternal'"
                      href="javascript:void(0)"
                      class="label theme-bg3 text-white f-12"
                      >{{ $t("badge.external-letter") }}</a
                    >
                  </template>
                  <span>{{ $t("tooltip.external-letter") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      v-if="singleRevision.memo.tipe === 'izin_prinsip'"
                      href="javascript:void(0)"
                      class="label theme-bg4 text-white f-12"
                      >{{ $t("badge.permit-memo") }}</a
                    >
                  </template>
                  <span>{{ $t("tooltip.permit-memo") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      v-if="singleRevision.memo.tag.includes('urgent')"
                      href="javascript:void(0)"
                      class="label theme-bg3 text-white f-12"
                      >{{ $t("badge.urgent") }}</a
                    >
                  </template>
                  <span>{{ $t("tooltip.urgent") }}</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle
                class="text--darken-1 text-bolder"
                :class="{
                  'red--text text-uppercase': isTerminated(
                    singleRevision.memo.memo_status_id
                  ),
                  'blue--text': !isTerminated(
                    singleRevision.memo.memo_status_id
                  ),
                }"
              >
                <v-icon
                  class="text--lighten-1"
                  :class="{
                    'red--text text-uppercase': isTerminated(
                      singleRevision.memo.memo_status_id
                    ),
                    'blue--text': !isTerminated(
                      singleRevision.memo.memo_status_id
                    ),
                  }"
                  >{{ iconInfo }}</v-icon
                >
                {{ singleRevision ? singleRevision.memo.status_label : "" }}
                <span
                  v-if="isTerminated(singleRevision.memo.memo_status_id)"
                  class="d-block ml-7"
                  >{{
                    `${$t("text.terminated-by")} ${
                      singleRevision.nama_reviser_terakhir
                    }`
                  }}</span
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <div class="table-responsive-container">
              <div class="table-responsive info__container">
                <table class="draft__info-table">
                  <tr v-if="singleRevision.memo.tipe === 'masuk'">
                    <td>{{ $t("label.sender institution") }}</td>
                    <td>:</td>
                    <td class="recipient__column blue--text text-bolder">
                      {{ singleRevision.memo.lembaga_pengirim_surat }}
                    </td>
                  </tr>
                  <tr v-if="singleRevision.memo.tipe !== 'eksternal'">
                    <td>{{ $t("label.recipients") }}</td>
                    <td>:</td>
                    <td class="recipient__column">
                      <v-chip
                        v-for="item in singleRevision.penerima"
                        :key="item.id"
                        class="mr-1 mb-1 recipient__chip"
                      >
                        {{ ['subholding','xnusantara'].includes(singleRevision.memo.tipe) ? item.alias : item.nama }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.letter-subject") }}</td>
                    <td>:</td>
                    <td class="recipient__column" style="font-weight: 600">
                      {{ singleRevision.memo.hal }}
                    </td>
                  </tr>
                  <tr v-if="singleRevision.memo.tipe === 'eksternal'">
                     <td>{{ $t("label.recipients") }}</td>
                     <td>:</td>
                     <td class="recipient__column">
                        <ol>
                        <li v-for="(item,index) in singleRevision.penerima" :key="index">
                          <span style="display: block; width: 100%;">{{ item.nama }}</span>
                        </li>
                      </ol>
                        <span v-if="parseInt(singleRevision.memo.kepada_alamat) === 2" style="padding-left: 1.8rem;">
                           Di&nbsp;{{ singleRevision.memo.kepada_line_2 }}
                        </span>
                        <span v-if="parseInt(singleRevision.memo.kepada_alamat) === 3 && singleRevision.kepada_line_2 != ''" style="padding-left: 1.8rem;">
                           {{ singleRevision.memo.kepada_line_2 }}
                        </span>
                       <br />
                       <span  v-if="parseInt(singleRevision.memo.kepada_alamat) === 3" style="padding-left: 1.8rem;">
                           Di&nbsp;{{ singleRevision.memo.kepada_line_3 }}
                        </span>
                     </td>
                   </tr>
                   <tr v-if="singleRevision.memo.tipe === 'eksternal' && singleRevision.tembusan">
                     <td>{{ $t("label.carbon-copy") }}</td>
                     <td>:</td>
                     <td class="recipient__column">
                        <ol>
                        <li v-for="(item,index) in singleRevision.tembusan" :key="index">
                          <span style="display: block; width: 100%;">{{ item.nama }}</span>
                        </li>
                      </ol>
                     </td>
                   </tr>
                   <tr v-if="singleRevision.tembusan">
                     <td>{{ $t("label.carbon-copy") }}</td>
                     <td>:</td>
                     <td class="recipient__column">
                       <v-chip
                         v-for="item,idx in singleRevision.tembusan"
                         :key="idx"
                         class="mr-1 mb-1 recipient__chip"
                       >
                         {{item.nama}}
                       </v-chip>
                     </td>
                   </tr>
                  <tr v-if="['bom','izin_prinsip'].includes(singleRevision.memo.tipe)">
                     <td>{{ $t("label.approver x letter") }}</td>
                     <td>:</td>
                     <td>
                       <strong class="text-bolder blue--text"
                         >{{
                           singleRevision.memo.approver_name
                             ? singleRevision.memo.approver_name
                             : ""
                         }}
                       </strong>
                       <br />
                       {{
                         singleRevision.pengirim
                           ? singleRevision.pengirim.name
                           : ""
                       }}
                     </td>
                   </tr>
                  <tr>
                    <td colspan="3" style="height: 20px">&nbsp;</td>
                  </tr>
                  <tr v-if="['eksternal','xnusantara','subholding'].includes(singleRevision.memo.tipe)">
                    <td>{{ $t("label.approver x letter") }}</td>
                    <td>:</td>
                    <td>
                      <ol>
                        <li v-for="(item,index) in singleRevision.signers" :key="index">
                          <span style="display: block; width: 100%;" :class="index === (singleRevision.signers.length -1) ? 'text-bolder blue--text' : ''">{{ item.nama }}</span>
                          <span style="display: block; width: 100%;">({{ item.nama_jabatan }})</span>
                        </li>
                      </ol>
                      
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.draft-status") }}</td>
                    <td>:</td>
                    <td>
                      {{ singleRevision ? singleRevision.memo.status_label : "" }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.created-by") }}</td>
                    <td>:</td>
                    <td>
                      {{ singleRevision ? singleRevision.creator.nama : "" }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.created-at") }}</td>
                    <td>:</td>
                    <td>
                      {{
                        singleRevision
                          ? indoDate(singleRevision.memo.created_at)
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" style="height: 20px">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.last-reviser") }}</td>
                    <td>:</td>
                    <td>
                      {{
                        singleRevision
                          ? singleRevision.memo.nama_reviser_terakhir
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.last-revised-at") }}</td>
                    <td>:</td>
                    <td>
                      {{
                        singleRevision
                          ? indoDate(singleRevision.data_time)
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.reviser-list") }}</td>
                    <td>:</td>
                    <td>
                      <a
                        href="javascript:;"
                        v-for="(item, idx) in revisers"
                        :key="idx"
                        class="d-block reviser__link"
                        @click="showRevisionDetails(item.id_hash, item.nama)"
                      >
                        <v-avatar left class="reviser__avatar-icon">
                          <v-icon class="blue--text text--lighten-2">{{
                            iconUser
                          }}</v-icon>
                        </v-avatar>
                        <span class="reviser__link-name">
                          {{ `${idx + 1}. ${item.nama}` }}
                        </span>
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6" sm="12" class="d-flex flex-column">
        <v-card class="flex" outlined tile>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="headline">
                {{ $t("card.revision-tracking") }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                $t("text.traces-the-concept")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-text class="mx-2">
            <div class="table-responsive max-h-500" v-if="trackingList">
              <div>
                <ul class="history-awb-timeline">
                  <li v-for="(item, index) in trackingList" :key="index">
                    <div class="d-flex flex-row">
                      <div
                        class="history-date d-flex flex-column"
                        v-if="isUniqueDate(index)"
                      >
                        <p>{{ mydate(item.created_at, "dayname") }},</p>
                        <h5>{{ mydate(item.created_at, "date") }}</h5>
                      </div>
                      <div class="history-date d-flex flex-column" v-else>
                        <p></p>
                        <h5></h5>
                      </div>
                      <div class="history-detail d-flex flex-column">
                        <p style="margin: 0px">
                          {{ mydate(item.created_at, "time") }}
                        </p>
                        <p style="margin: 0px" v-html="item.action"></p>
                        <p v-html="item.desc"></p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="blue lighten-2"
              @click="showHideLogDialog(true)"
              data-hintposition="middle-middle"
            >
              <v-icon class="mr-2">{{ iconLog }}</v-icon>
              {{ $t("button.log-detail") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <log-modal
      v-bind:showLogDialog="showLogDialog"
      v-bind:memoId="singleRevision.memo.id_hash"
      v-on:showHideLogDialog="showHideLogDialog"
    ></log-modal>
    <revision-info-modal
      v-bind:showRevInfoDialog="showRevInfoDialog"
      v-bind:memoRevId="memoRevId"
      v-bind:reviserName="reviserName"
      v-on:showHideRevInfoDialog="showHideRevInfoDialog"
    ></revision-info-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import {
  mdiAccount,
  mdiAccountGroup,
  mdiInformation,
  mdiCalendarTextOutline,
} from "@mdi/js";
import LogModal from "@/components/commonComponents/LogModal.vue";
import RevisionInfoModal from "@/components/commonComponents/RevisionInfoModal.vue";
export default {
  name: "history-detail",
  components: {
    LogModal,
    RevisionInfoModal,
  },

  data: () => ({
    iconUser: mdiAccount,
    iconGroup: mdiAccountGroup,
    iconInfo: mdiInformation,
    iconLog: mdiCalendarTextOutline,
    showLogDialog: false,
    showRevInfoDialog: false,
    memoRevId: undefined,
    reviserName: undefined,
  }),
  computed: {
    ...mapState("draft", [
      "singleDraftHistory",
      "singleRevision",
    ]),
    trackingList() {
      if (!this.singleDraftHistory) return undefined;
      return this.singleDraftHistory;
    },
    recipients() {
      let result = [];
      if (!this.singleRevision && !this.singleRevision) return result;

      if (this.singleRevision.penerima) result = this.singleRevision.penerima;
      if (this.singleRevision.penerima) result = this.singleRevision.penerima;
      return result;
      // let result = this.singleDraftInfo.penerima.map(({ name }) => name);
      // return result.join();
    },
    carbonCopy() {
      if (!this.singleRevision) return undefined;

      if (
        this.singleRevision.memo.tipe === 'eksternal' &&
        this.singleRevision.memo.tembusan_surat_eksternal
      ) {
        var rv = [];
        let item = {};
        let arr = this.singleRevision.memo.tembusan_surat_eksternal.split(",");
        for (var i = 0; i < arr.length; ++i) {
          item = { id: i, name: arr[i] };
          rv.push(item);
        }
        return rv;
      }
      return this.singleRevision.tembusan;
    },
    revisers() {
      if (!this.singleRevision) return undefined;
      return this.singleRevision.revisers;
    },
  },
  methods: {
    ...mapActions("draft", [
      "setDraftHistory",
      "getDraftHistory",
      "setDraftLog",
      "setSingleRevisionInfo",
    ]),
    isUniqueDate(trackingIndex) {
      return this.checkDateTrackingItem(trackingIndex);
    },
    showHideLogDialog(val) {
      this.showLogDialog = val;
      if (!val) {
        this.setDraftLog(undefined);
      }
    },
    showHideRevInfoDialog(val) {
      this.showRevInfoDialog = val;
      if (!val) {
        this.memoRevId = undefined;
        this.reviserName = undefined;
        this.setSingleRevisionInfo(undefined);
      }
    },
    showRevisionDetails(revId, reviserName) {
      if (!revId) return;
      this.memoRevId = {
        memoId: this.singleRevision.memo.id_hash,
        revisionId: revId,
      };
      this.reviserName = reviserName;
      this.showRevInfoDialog = true;
    },
    checkDateTrackingItem(trackingIndex) {
      const previousItem = this.singleDraftHistory[trackingIndex - 1];
      // const nextItem = this.trackingItems[trackingIndex + 1];
      const currentItem = this.singleDraftHistory[trackingIndex];
      if (!previousItem) {
        return true;
      }
      const prevDate = this.mydate(previousItem.created_at, "date");
      // const nextDate = nextItem ? nextItem.date : null;
      const currDate = this.mydate(currentItem.created_at, "date");

      if (prevDate !== currDate) return true;
      return false;
    },
    isTerminated(val) {
      return [19.29, 39, 49, 59].includes(parseInt(val));
    },
  },
};
</script>
