<template>
  <div v-if="singleMemo">
    <v-container fluid>
      <div>
        <span>{{ $t("label.memo-number") }}</span>
        <v-list-item-subtitle
          class="text-bolder mb-4"
          style="max-width: 600px; font-size: 1.2rem !important"
          >{{ memoRef.nomor }}
          <v-btn
            small
            depressed
            color="primary"
            class="ml-2"
            @click="gotoInboxDetail"
            v-if="userIsPjp"
          >
            <v-icon dark>{{ iconGoto }}</v-icon>
            {{ $t("button.goto-reference") }}
          </v-btn>
        </v-list-item-subtitle>
      </div>
      <div>
        <span>{{ $t("text.received-at") }}</span>
        <v-list-item-subtitle
          class="text-bolder mb-4"
          style="max-width: 600px; font-size: 1.2rem !important"
          >{{$date(memoRef.tgl).format("DD MMM YYYY HH:mm WIB") }}
        </v-list-item-subtitle>
      </div>
      <div>
        <span>{{ $t("label.memo-sender") }}</span>
        <v-list-item-subtitle
          class="text-bolder mb-4"
          style="max-width: 600px; font-size: 1.2rem !important"
          >{{ memoRef.pengirim.nama }}
        </v-list-item-subtitle>
      </div>
     
      <div class="mb-3">
        <span>{{ $t("label.memo-subject") }}</span>
        <v-list-item-subtitle
          class="text-bolder mb-4"
          style="max-width: 600px; font-size: 1.2rem !important"
          >{{ memoRef.hal }}
        </v-list-item-subtitle>
      </div>
      <p class="mb-2">{{ $t("label.memo-message") }}</p>
      <hr class="gray-line">
      <froalaView v-model="memoRef.isi"></froalaView>
      <hr class="gray-line mt-4">
      <v-card-text class="mb-4">
        <v-row>
          <v-col
            cols="12"
            md="6"
            sm="12"
            v-if="memoRef && memoRef.penerima"
          >
            <h4 class="mt-3 mb-3">{{ $t("text.memo-recipients") }}</h4>

            <v-chip
              v-for="item in memoRef.penerima"
              :key="item.id"
              class="mr-1 mb-1 recipient__chip"
            >
              {{ item.nama }}
            </v-chip>
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="12"
            v-bind:class="{
              'cc__border-left': !$vuetify.breakpoint.smAndDown,
            }"
            v-if="
              memoRef &&
              memoRef.tembusan &&
              memoRef.tembusan.length > 0
            "
          >
            <h4 class="mt-3 mb-3">{{ $t("text.memo-carbon-copy") }}</h4>
            <v-chip
              v-for="item in memoRef.tembusan"
              :key="item.id"
              class="mr-1 mb-1 recipient__chip"
            >
              {{ item.nama }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </div>
</template>
<script>
import { mdiInformationOutline, mdiLinkVariant } from "@mdi/js";
import { mapState } from "vuex";
export default {
  name: "reference",
  props: {
    singleMemo: { type: [Object, Array] },
  },
  data: () => ({
    iconInfo: mdiInformationOutline,
    iconGoto: mdiLinkVariant,
  }),
  computed: {
    ...mapState("user", ["currentUser"]),
    memoRef() {
      if (!this.singleMemo || !this.singleMemo.memo_ref) return undefined;
      return this.singleMemo.memo_ref;
    },
     userIsPjp() {
      if (!this.currentUser) return false;
      return ["PJP", "PJP_KANPUS"].includes(this.currentUser.level_aplikasi_elemen);
    },
  },
  methods: {
    gotoInboxDetail() {
      let route = this.$router.resolve({path: `/memo/inbox/${this.memoRef.id}`});
      window.open(route.href, '_blank');
    },
  },
};
</script>